@import "@src/constants/dimensions.scss";

$breakpoint: $small-medium-width;
$hero-size: 1200px;

.hero-section {
  display: flex;
  width: 100%;
  max-width: $hero-size;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  background-color: var(--container);
  text-align: center;

  @media (min-width: $breakpoint) {
    flex-direction: row-reverse;
    padding: 48px 48px 0;
  }
}

.hero-phone {
  $m: 48px;
  display: inline-block;
  width: 600px;
  max-width: calc(100% - 2 * $m);
  box-sizing: border-box;
  margin: $m;
}

.hero-title {
  margin: 0 24px;
  font-size: 64px;
  font-weight: 400;
  line-height: 58px;
  text-align: left;
}

.hero-subtitle {
  margin: 24px;
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}

.hero-form {
  text-align: left;
}

.image-wrapper {
  width: 100%;

  @media (min-width: $breakpoint) {
    width: 72%;
    max-width: 1000px;
  }
}

.text-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (min-width: $breakpoint) {
    width: 50%;
    max-width: 500px;
  }
}

.email-button,
.legal-subtext {
  align-self: flex-start;
}
